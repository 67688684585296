export default defineNuxtRouteMiddleware(async () => {
	const user = useUserStore().user

	if (user) {
		return
	}

	const headers = useRequestHeaders(['x-requested-with'])
	const requestedWith = headers['x-requested-with'] || ''

	const isMobile = requestedWith === 'net.realityavatar.app'
	const redirectUrl = !isMobile ? '/lp' : '/welcome'

	return navigateTo(redirectUrl)
})
